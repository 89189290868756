import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { environment } from '@environments/environment'
import { filter } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	constructor(private router: Router) {
		this.trackPageViews()
	}

	// Método para registrar eventos
	logEvent(nameEvent: string, category: string, label: string, value: any) {
		if (!environment.production) return
		gtag('event', nameEvent, {
			event_category: category,
			event_label: label,
			...value,
		})
	}

	private trackPageViews(): void {
		if (!environment.production) return

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
			gtag('event', 'page_view', { page_path: event.urlAfterRedirects })
			console.log(`Page view tracked: ${event.urlAfterRedirects}`)
		})
	}
}
